import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'

import { AnimationFadeIn } from 'shared/Animation/Animation'

const s = {
  container: `w-top fixed bottom-10 z-9 flex justify-end top:w-11/12`,
  button: `no-underline bg-transparent border-0 curser-pointer focus:outline-none`,
  image: `w-10 h-10 invert`,
}

const Top = () => {
  const datas = useStaticQuery(graphql`
    query {
      contentfulTop {
        title
        images {
          title
          file {
            details {
              image {
                width
                height
              }
            }
            url
          }
        }
      }
    }
  `)

  const handleClick = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
  }

  const { title, images } = datas.contentfulTop

  return (
    <section className={`${s.container}`}>
      <button className={s.button} title={title} onClick={() => handleClick()}>
        <AnimationFadeIn delay={100} x={100} y={0} shows={false}>
          <img
            className={s.image}
            src={images.file.url}
            alt={images.title}
            width={images.file.details.image.width}
            height={images.file.details.image.height}
          />
        </AnimationFadeIn>
      </button>
    </section>
  )
}

export default Top
