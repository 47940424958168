import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { AnchorLink } from 'gatsby-plugin-anchor-links'

interface Filters {
  filters: boolean
}

const s = {
  container: (filters: any) => ` ${filters ? `invert` : `invert-0`}
  relative z-20 w-48 flex items-center transition-all delay-300 ease-in-out duration-300`,
  flex: `flex items-center`,
  link: `font-sub font-bold text-h3 text-white`,
  title: `capitalize text-white`,
  image: `w-24 h-24 mr-4`,
}

const Logo = ({ filters }: Filters) => {
  const datas = useStaticQuery(graphql`
    query {
      contentfulLogo {
        title
        link
      }
    }
  `)

  const { title, link } = datas.contentfulLogo

  return (
    <div className={`${s.container(filters)} `}>
      <AnchorLink to={`/${link}`} stripHash className={s.link}>
        <h3 className={s.title}>{title}</h3>
      </AnchorLink>
    </div>
  )
}

export default Logo
