export const s = {
  // FORM
  form: `w-full `,
  flex: `w-full flex justify-between xlx:flex-col`,

  group: `flex flex-col mb-12 first:-mt-4`,
  label: `mb-6 text-2xl font-bold text-white`,
  input: `p-4 text-lg font-inherit rounded border-1 border-solid border-white bg-white text-darkgray focus:outline-none`,
  select: `p-4 text-lg font-inherit border-1 border-solid border-white bg-white text-darkgray focus:outline-none`,
  option: `text-lg font-inherit bg-white text-darkgray border-1 border-solid border-white hover:text-red`,
  message: `h-60 resize-y p-4 text-lg font-inherit rounded border border-solid border-white bg-white text-darkgray focus:outline-none`,
  button: ``,

  // CHECK BOX
  checkbox: `relative w-fit-content mt-12 mb-28`,
  checklabel: `flex align-center mb-0 text-lg cursor-pointer checklabel`,
  checkspan: `block checkspan`,
  checkinput: `w-9 h-9 mr-4 appearance-none bg-white border-1 border-solid border-white rounded outline-none transition delay-300 ease-in curser-pointer checkinput checked:bg-white checked:border checked:border-solid checked:border-white active:border active:border-solid active:border-darkgray`,

  // MESSAGE WARNING
  error: `mt-3 text-red`,
}
