import React, { useState } from 'react'
import { InView } from 'react-intersection-observer'
import { useSpring, useTrail, animated } from 'react-spring'
import { easeSinOut, easeQuadInOut, easeLinear } from 'd3-ease'

const s = {
  group: (shows: any) => `
  w-full h-full ${shows ? 'overflow-hidden' : 'overflow-visible'}`,
  block: (shows: any) => `
  first:absolute top-0 z-10 w-full h-full
  ${shows ? 'overflow-hidden' : 'overflow-visible'}`,
}

export const AnimationFadeIn = ({ x, y, shows, delay, children }: any) => {
  const [visible, setVisible] = useState(false)

  const onChange = (inView: boolean) => {
    if (!visible && inView) setVisible(true)
  }

  const props = useSpring({
    to: { opacity: visible ? 1 : 0, x: visible ? 0 : x, y: visible ? 0 : y },
    delay: delay,
    config: {
      easing: easeSinOut,
      duration: 800,
    },
  })

  return (
    <InView onChange={onChange}>
      <animated.div className={s.group(shows)} style={props}>
        {children}
      </animated.div>
    </InView>
  )
}

export const AnimationSlideIn = ({ x, y, shows, delay, children }: any) => {
  const [visible, setVisible] = useState(false)

  const onChange = (inView: boolean) => {
    if (!visible && inView) setVisible(true)
  }

  const props = useSpring({
    to: { x: visible ? 0 : x, y: visible ? 0 : y },
    delay: delay,
    config: {
      easing: easeQuadInOut,
      duration: 800,
    },
  })

  return (
    <InView onChange={onChange}>
      <animated.div className={s.group(shows)} style={props}>
        {children}
      </animated.div>
    </InView>
  )
}

export const AnimationTrail = ({ x, y, shows, delay, children }: any) => {
  const [visible, setVisible] = useState(false)

  const onChange = (inView: boolean) => {
    if (!visible && inView) setVisible(true)
  }

  const items = React.Children.toArray(children)

  const trial = useTrail(items.length, {
    to: {
      opacity: visible ? 1 : 0,
      x: visible ? 0 : x,
      y: visible ? 0 : y,
    },
    delay: delay,
    config: {
      easing: easeSinOut,
      duration: 800,
    },
  })

  return (
    <InView onChange={onChange}>
      {trial.map(({ ...style }: any, index: number) => (
        <animated.div key={index} className={s.group(shows)} style={style}>
          {items[index]}
        </animated.div>
      ))}
    </InView>
  )
}

export const AnimationBlockSlide = ({ shows, bgcolor, delay }: any) => {
  const [visible, setVisible] = useState(false)

  const onChange = (inView: boolean) => {
    if (!visible && inView) setVisible(true)
  }

  const props = useSpring({
    to: {
      width: visible ? '0%' : '100%',
    },
    delay: delay,
    config: {
      easing: easeLinear,
      duration: 800,
    },
  })

  return (
    <InView onChange={onChange}>
      <animated.div className={`${s.block(shows)} ${bgcolor}`} style={props} />
    </InView>
  )
}
