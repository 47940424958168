import React from 'react'

import Form from 'main/Contact/Form'

const s = {
  container: `w-full h-fit-content`,
}

const Contact = () => {
  return (
    <section className={s.container}>
      <Form />
    </section>
  )
}

export default Contact
