import React, { useEffect } from 'react'
import { useStaticQuery, graphql } from 'gatsby'

import { useMenuContext } from 'hooks/menu-context'

import { AnimationFadeIn } from 'shared/Animation/Animation'
import Nav from 'main/Header/Nav'

interface Backgrounds {
  backgrounds?: string
}

const s = {
  container: `fixed top-0 left-0 z-10 w-full h-fit-content 
  transition-all delay-300 ease-out duration-500`,
  wrapper: `wrapper`,
}

const Header = ({ backgrounds }: Backgrounds) => {
  const { showNav, setShowNav } = useMenuContext()

  const datas = useStaticQuery(graphql`
    query {
      allContentfulNav {
        nodes {
          id
          title
          link
        }
      }
    }
  `)

  useEffect(() => {
    window.addEventListener('scroll', showNavBG)

    return () => window.removeEventListener('scroll', showNavBG)
  })

  const showNavBG = () => {
    window.scrollY > 0 ? setShowNav(true) : setShowNav(false)
  }

  return (
    <section className={`${s.container} ${showNav ? `bg-black` : backgrounds}`}>
      <div className={s.wrapper}>
        <AnimationFadeIn delay={800} x={0} y={-10}>
          <Nav datas={datas} />
        </AnimationFadeIn>
      </div>
    </section>
  )
}

export default Header
