import React from 'react'
import { Helmet } from 'react-helmet'
import { useStaticQuery, graphql } from 'gatsby'

interface Datas {
  title: string
  description: any
  lang?: string
  siteUrl?: string
  keywords?: string
}

function SEO({ title, description, siteUrl, keywords }: Datas) {
  const datas = useStaticQuery(
    graphql`
      query {
        contentfulSeo {
          title
          description
          author
          siteUrl
          keywords
        }
      }
    `
  )

  const dataSite = datas.contentfulSeo

  const metaTitle = title || dataSite.title
  const metaDescription = description || dataSite.description
  const metaUrl = siteUrl || dataSite.siteUrl
  const metaKeywords = keywords || dataSite.keywords

  return (
    <Helmet
      htmlAttributes={{
        lang: 'en',
      }}
      title={title}
      titleTemplate={`${title} | ${description.slice(0, 25)}...`}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: metaTitle,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: metaUrl,
        },
        {
          name: `twitter:card`,
          content: metaDescription,
        },
        {
          name: `twitter:creator`,
          content: dataSite.author,
        },
        {
          name: `twitter:title`,
          content: metaTitle,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
      ].concat(
        metaKeywords && metaKeywords.length > 0
          ? {
              name: `keywords`,
              content: metaKeywords.join(`, `),
            }
          : []
      )}
    />
  )
}

export default SEO
