import React from 'react'
import Markdown from 'markdown-to-jsx'

import { AnimationFadeIn } from 'shared/Animation/Animation'

import Logo from 'shared/Logo/Logo'
import Social from 'shared/Social/Social'
import Contact from 'main/Contact/Contact'

interface Datas {
  datas: {
    contentfulFooter: {
      description: any
      titlecontact: string
    }
  }
}

const s = {
  container: `w-full h-fit-content pb-28`,
  grid: `grid-2 gap-20 mdx:grid-cols-1 mdx:gap-y-28`,
  flex: `w-full h-full flex flex-col text-white`,

  title: `mb-16 text-3xl text-white`,
  description: `w-w40 mt-8 mb-16 text-base leading-10 lgx:w-5/6`,
  list: `list-none flex flex-col`,
  items: `pb-4 text-white link`,
  email: `mb-0 text-sm`,
}

const Content = ({ datas }: Datas) => {
  const { description, titlecontact } = datas.contentfulFooter

  return (
    <section className={s.container}>
      <AnimationFadeIn delay={200} x={0} y={0}>
        <div className={s.grid}>
          <div className={s.flex}>
            <Logo filters={false} />
            <Markdown className={s.description}>
              {description.description}
            </Markdown>
            <Social filters={false} direction="row" />
          </div>

          <div className={s.flex}>
            <h3 className={s.title}>{titlecontact}</h3>
            <Contact />
          </div>
        </div>
      </AnimationFadeIn>
    </section>
  )
}

export default Content
