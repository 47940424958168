import React from 'react'

import { s } from 'shared/Button/_button'

interface Button {
  disabled: boolean
  classname: string
  children: any
}

const FormButton = ({ disabled, classname, children }: Button) => {
  return (
    <button
      type="submit"
      disabled={disabled}
      className={`${s.button} ${classname}`}
    >
      {children}
    </button>
  )
}

export default FormButton
