import React, { useEffect } from 'react'

import { useMenuContext } from 'hooks/menu-context'

interface Filters {
  filters?: boolean
}

const s = {
  container: `w-16 h-16 md:invisible md:opacity-0`,
  span: `block w-12 h-1 rounded bg-white transition-all delay-300 ease-out duration-500`,
  menu: `fixed top-10 right-4.75 z-99 p-0 border-transparent cursor-pointer focus:outline-none`,
}

const Hamburger = ({ filters }: Filters) => {
  const { clickMenu, setClickMenu } = useMenuContext()

  const handleClick = () => setClickMenu(!clickMenu)

  useEffect(() => {
    const idElement = document.getElementById('menu')

    const handleOutClick = (e: MouseEvent) => {
      if (!idElement?.contains(e.target as HTMLElement)) {
        setClickMenu(false)
      }
    }

    window.addEventListener('click', handleOutClick)
    return () => {
      window.removeEventListener('click', handleOutClick)
    }
  })

  return (
    <section className={s.container} id="menu">
      <div className={s.menu} onClick={handleClick}>
        <span
          className={`${s.span} 
            ${filters ? `invert` : `invert-0`} 
            ${clickMenu ? `rotate-45 origin-16` : `rotate-0 origin-0`}       
          `}
        />
        <span
          className={`${s.span} my-2.5 mx-0 
            ${filters ? 'invert' : 'invert-0'} 
            ${
              clickMenu
                ? `opacity-0 translate-x-full`
                : `opacity-100 translate-x-0`
            }
          `}
        />
        <span
          className={`${s.span} 
            ${filters ? 'invert' : 'invert-0'} 
            ${clickMenu ? `-rotate-45 origin-2` : `rotate-0 origin-0`} 
          `}
        />
      </div>
    </section>
  )
}

export default Hamburger
