import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import toast, { Toaster } from 'react-hot-toast'
import isEmail from 'validator/lib/isEmail'
import axios from 'axios'

import { s } from 'main/Contact/_contactform'

import Checkbox from 'main/Contact/Checkbox'
import FormButton from 'shared/Button/FormButton'

interface Contact {
  name: string
  email: string
  subject: string
  message: string
  checkbox: boolean
  errors: any
}

const Form = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    formState,
  } = useForm<Contact>({
    mode: 'onChange',
  })

  const [values, setValues] = useState({
    name: '',
    email: '',
    subject: '',
    message: '',
    checkbox: false,
  })

  const { name, email, subject, message, checkbox } = values

  const handleChange = (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLSelectElement>
      | React.ChangeEvent<HTMLTextAreaElement>
  ): void => {
    setValues({ ...values, [e.target.name]: e.target.value, checkbox: true })
  }

  const onSubmit = handleSubmit((data, e: any) => {
    e.preventDefault()

    const form = e.target

    axios({
      method: 'post',
      url: 'https://formspree.io/f/mwkwvdvr',
      data: new FormData(form),
    })
      .then((response) => {
        if (checkbox) e.target.reset()

        toast.success(`Thanks for reaching out. I'll get back to you soon.`)

        setValues({
          ...values,
          name: '',
          email: '',
          subject: '',
          message: '',
          checkbox: false,
        })
      })
      .catch((error) => {
        console.log(error)
        toast.error(
          'Oops, something went wrong. The form could not be submitted.'
        )
        setValues({ ...values, checkbox: false })
      })
  })

  return (
    <div className="w-full h-fit-content">
      <Toaster position="top-center" reverseOrder={false} />

      <form name="contact" onSubmit={onSubmit} className={s.form}>
        <div className={s.group}>
          <label htmlFor="name" className={`${s.label} form-label`}>
            Name
          </label>
          <input
            {...register('name', {
              required: true,
              minLength: 3,
              maxLength: 20,
              pattern: /^[A-Za-z]+$/i,
            })}
            className={s.input}
            id="name"
            type="text"
            name="name"
            placeholder="Name"
            value={name}
            onChange={handleChange}
          />
          {errors.name && <p className={s.error}>Please enter a valid name.</p>}
        </div>

        <div className={s.group}>
          <label htmlFor="email" className={`${s.label} form-label`}>
            Email
          </label>
          <input
            {...register('email', {
              required: true,
              validate: (input: any) => isEmail(input),
              minLength: 6,
              pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+.[A-Z]{2,4}$/i,
            })}
            className={s.input}
            id="email"
            type="email"
            name="email"
            placeholder="Email"
            value={email}
            onChange={handleChange}
          />
          {errors.email && (
            <p className={s.error}>Please enter a valid email.</p>
          )}
        </div>

        <div className={s.group}>
          <label htmlFor="subject" className={`${s.label} form-label`}>
            Subject
          </label>
          <input
            {...register('subject', {
              required: true,
              minLength: 3,
              maxLength: 100,
              pattern: /^[A-Za-z]+$/i,
            })}
            className={s.input}
            id="subject"
            type="text"
            name="subject"
            placeholder="Email"
            value={subject}
            onChange={handleChange}
          />
          {errors.subject && (
            <p className={s.error}>Please enter a valid subject.</p>
          )}
        </div>

        <div className={s.group}>
          <label htmlFor="message" className={`${s.label} form-label`}>
            Message
          </label>
          <textarea
            {...register('message', {
              required: true,
              maxLength: 300,
            })}
            className={s.message}
            id="message"
            name="message"
            placeholder="Message"
            value={message}
            onChange={handleChange}
          />
          {errors.message && (
            <p className={s.error}>Please enter a valid message.</p>
          )}
        </div>

        <Checkbox
          register={register}
          handleChange={handleChange}
          errors={errors}
        />

        <FormButton classname={s.button} disabled={formState.isSubmitting}>
          Send
        </FormButton>
      </form>
    </div>
  )
}

export default Form
