import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'

interface Datas {
  filters?: boolean
  direction: any
}

interface Social {
  id: number
  title: string
  link: string
  images: {
    title: string
    file: {
      details: {
        image: {
          width: number
          height: number
        }
      }
      url: string
    }
  }
}

const s = {
  container: (filters: any) => ` ${filters ? `invert-0` : `invert`}
  w-32 h-fit-content flexbetween transition-all delay-300 ease-in-out`,
  link: `mb-0`,
  image: `w-8 h-8`,
}

const Social = ({ filters, direction }: Datas) => {
  const datas = useStaticQuery(graphql`
    query {
      allContentfulSocial {
        nodes {
          id
          title
          link
          images {
            title
            file {
              details {
                image {
                  width
                  height
                }
              }
              url
            }
          }
        }
      }
    }
  `)

  const socials = datas.allContentfulSocial.nodes

  return (
    <section className={`${s.container(filters)} ${direction}`}>
      {socials.map(({ id, title, link, images }: Social) => (
        <a className={s.link} key={id} href={link} title={title}>
          <img
            className={s.image}
            src={images.file.url}
            alt={images.title}
            width={images.file.details.image.width}
            height={images.file.details.image.height}
          />
        </a>
      ))}
    </section>
  )
}

export default Social
