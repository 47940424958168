import React, { useEffect } from 'react'
import { AnchorLink } from 'gatsby-plugin-anchor-links'

import { useMenuContext } from 'hooks/menu-context'

import Logo from 'shared/Logo/Logo'
import Hamburger from 'main/Header/Hamburger'
import Social from 'shared/Social/Social'

interface Datas {
  datas: {
    allContentfulNav: {
      nodes: {
        id: number
        title: string
        link: string
      }[]
    }
  }
}

const s = {
  menu: `w-full h-28 flex align-center`,
  navlist: (click: any) => `
  relative list-none w-full h-full flex justify-end items-center overflow-hidden 
  ${
    click
      ? `mdx:visible mdx:opacity-100 mdx:invert`
      : `mdx:invisible mdx:opacity-0 mdx:invert-0`
  }
  mdx:fixed mdx:top-0 mdx:left-0 mdx:w-full mdx:h-full mdx:flex-col mdx:justify-center mdx:text-center mdx:bg-black mdx:transition-all mdx:delay-300 mdx:ease-out mdx:duration-500`,
  group: `w-48 flex items-center justify-end invert-1 
  mdx:w-full mdx:justify-center mdx:invert mdx:mt-12`,
  items: `mr-20 capitalize overflow-hidden link mdx:mr-0 mdx:py-2.5`,
  link: `text-white font-inherit text-clamp-sm transition-all delay-200 ease-out hover:text-red hover:mdx:invert screensm:m-0 screensm:uppercase screensm:focus:outline-none mdm:text-2xl smm:text-lg xsx:text-2xl`,
}

const Nav = ({ datas }: Datas) => {
  const { clickMenu } = useMenuContext()

  const links = datas.allContentfulNav.nodes

  useEffect(() => {
    window.addEventListener('scroll', activeLink)

    return () => window.removeEventListener('scroll', activeLink)
  })

  const activeLink = () => {
    let current = ''
    const sections = document.querySelectorAll('.section')
    const links = document.querySelectorAll('.link')

    sections.forEach((section: any) => {
      const sectionTop = section.offsetTop
      const sectionHeight = section.clientHeight
      const sectionPos = sectionTop - sectionHeight / 3

      if (sectionTop !== null && window.scrollY >= sectionPos) {
        current = section.getAttribute('id')
      }
    })

    links.forEach((link: any) => {
      link.classList.contains(current)
        ? link.classList.add('active')
        : link.classList.remove('active')
    })
  }

  return (
    <nav className={s.menu}>
      <Logo filters={clickMenu} />
      <Hamburger filters={clickMenu} />
      <ul className={s.navlist(clickMenu)}>
        {links.map(({ id, title, link }) => (
          <li key={id} className={`${s.items} ${title.toLowerCase()}`}>
            <AnchorLink to={`/${link}`} stripHash className={s.link}>
              {title}
            </AnchorLink>
          </li>
        ))}
        <li className={s.group}>
          <Social filters={clickMenu} direction="flex-row" />
        </li>
      </ul>
    </nav>
  )
}

export default Nav
