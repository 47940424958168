import React from 'react'

interface Datas {
  datas: {
    contentfulFooter: {
      titlecopy: string
    }
  }
}

const s = {
  container: `w-full h-fit-content mt-24 bg-black`,
  flex: `flex justify-start items-center`,
  copy: `mb-0 text-sm text-white`,
}

const Copy = ({ datas }: Datas) => {
  const { titlecopy } = datas.contentfulFooter

  return (
    <section className={s.container}>
      <div className={s.flex}>
        <p className={s.copy}>
          {`${titlecopy} © ${new Date().getFullYear()}, Built by JT `}
        </p>
      </div>
    </section>
  )
}

export default Copy
