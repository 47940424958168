import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { useInView } from 'react-intersection-observer'

import Content from 'main/Footer/Content'
import Copy from 'main/Footer/Copy'
import Top from 'shared/Top/Top'

const s = {
  container: `w-full h-fit-content pt-32 px-0 pb-16 bg-black section`,
  wrapper: `wrapper`,
}

const Footer = () => {
  const datas = useStaticQuery(graphql`
    query {
      contentfulFooter {
        slug
        description {
          description
        }
        titlecontact
        titlecopy
      }
      contentfulContact {
        slug
      }
    }
  `)

  const [ref, inView] = useInView({
    threshold: 0.5,
  })

  const { slug } = datas.contentfulContact

  return (
    <section ref={ref} className={s.container} id={slug}>
      <div className={s.wrapper}>
        <Content datas={datas} />
        <Copy datas={datas} />

        {inView && <Top />}
      </div>
    </section>
  )
}

export default Footer
