import React from 'react'

import Header from 'main/Header/Header'
import Footer from 'main/Footer/Footer'

interface Datas {
  backgrounds?: string
  children: any
}

const Layout = ({ backgrounds, children }: Datas) => {
  return (
    <div className="w-full min-h-screen flex flex-col">
      <Header backgrounds={backgrounds} />
      <main className="flex-initial">{children}</main>
      <Footer />
    </div>
  )
}

export default Layout
