import React from 'react'

import { s } from 'main/Contact/_contactform'

const ContactCheck = ({ register, handleChange, errors }: any) => {
  return (
    <section className={s.checkbox}>
      <label className={s.checklabel} htmlFor="checkbox">
        <input
          className={s.checkinput}
          {...register('checkbox', { required: true })}
          type="checkbox"
          name="checkbox"
          id="checkbox"
        />
        I'm a human.
        <span className={s.checkspan} onChange={handleChange}></span>
      </label>

      {errors.checkbox && <p className={s.error}>Please check the checkbox.</p>}
    </section>
  )
}

export default ContactCheck
